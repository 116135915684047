<template>
    <div>
      <el-select
        :value="selected"
        @input="dispatch"
        :placeholder="placeholder || $t('message.transport')"
        filterable
        clearable
        :size="size"
        class="w-100"
      >
        <el-option
          v-for="(transport, index) in transports"
          :key="'transports-' + index"
          :label="transport.name"
          :value="transport.id"
        ></el-option>
      </el-select>
    </div>
</template>
  <script>
  import { mapGetters, mapActions } from "vuex";
  export default {
    props: {
      size: {
        default: "small",
      },
      placeholder: {
        default: null,
      },
      id: {
        default: null,
      },
      board_id: {
        default: null,
      },
    },
    watch: {
      id: {
        handler: function () {
          this.selected = this.id;
        },
        immediate: true,
      },
    },
    data() {
      return {
        selected: null,
        requests: [
            {
                id: 1,
                name: 'Damas'
            },
            {
                id: 2,
                name: 'Labo'
            },
            {
                id: 2,
                name: 'Spark'
            },
        ]
      };
    },
    mounted() {
      if (this.transports && this.transports.length === 0) this.updateInventory();
    },
    computed: {
      ...mapGetters({
        transports: "transports/inventory",
      }),
    },
    methods: {
      ...mapActions({
        updateInventory: "transports/inventory",
      }),
      dispatch(e) {
        this.$emit("input", e);
        this.selected = e;
      },
    },
  };
  </script>
  